namespace app.publicPages.services {
	"use strict";

	export interface IRegisterYourDetailsService {
		readTermsAndConditions(): ng.IPromise<pages.interfaces.IPage>;
	}

	class RegisterYourDetailsService extends app.core.services.BaseService implements IRegisterYourDetailsService {
		private serviceBase: string = "/api/registeryourdetails/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readTermsAndConditions(): ng.IPromise<pages.interfaces.IPage> {
            return this.$http
                .get(this.serviceBase + "readtermsandconditions")
                .then((response: ng.IHttpPromiseCallbackArg<pages.interfaces.IPage>): pages.interfaces.IPage => response.data)
                .catch((error) => this.logError(error));
		};
	}

	angular
		.module("app.publicPages.services")
		.service("registerYourDetailsService", RegisterYourDetailsService);
}