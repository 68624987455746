// https://github.com/DanWahlin/AngularTypeScriptExpenseManager/blob/e0a52f112e4935b6003e7a28b3091ce4cc94fabf/src/public/app/expenseApp/services/httpInterceptors.ts
namespace app.core.angularConfiguration {
	"use strict";

	interface IApiInterceptorService {
		request(config: ng.IRequestConfig): ng.IRequestConfig;
		responseError(response: ng.IHttpPromiseCallbackArg<any>): ng.IPromise<any>;
	}

	class ApiInterceptorService implements IApiInterceptorService {
		request: (config: ng.IRequestConfig) => ng.IRequestConfig;
		responseError: (response: ng.IHttpPromiseCallbackArg<any>) => ng.IPromise<any>;

		static $inject: string[] = ["$location", "$q", "userCredentials"]
		constructor(
			private $location: ng.ILocationService,
			private $q: ng.IQService,
			private userCredentials: app.core.security.IUserCredentials) {

			this.request = (config) => this.requestInterceptor(config);
			this.responseError = (response) => this.responseErrorInterceptor(response);
		}

		requestInterceptor(config: ng.IRequestConfig): ng.IRequestConfig {
			if (!(config.method === "POST" && config.url.indexOf("/tokens") >= 0) && (config.url.slice(-5) !== ".html")) {
				let bearerToken = "Bearer " + this.userCredentials.getAccessToken();
				config.headers["Authorization"] = bearerToken.trim();
			}

            return config;
		}

		responseErrorInterceptor(response: ng.IHttpPromiseCallbackArg<any>): ng.IPromise<any> {
			if (response.status === 401 || response.status === 400) {
				this.userCredentials.logout();
           		this.$location.path("/login");
			}

			return this.$q.reject(response);
		}
	}

	angular
		.module("app.core.angularConfiguration")
		.service("apiInterceptorService", ApiInterceptorService);
}