namespace app.documents {
    "use strict";

    interface IDocumentViewVm {
        formLoading: boolean;
        record: interfaces.IDocument;
        documentFound: boolean;
    }

	export interface IDocumentViewParams extends ng.route.IRouteParamsService {
        id: string;
	}

    class DocumentViewController implements IDocumentViewVm {
        formLoading: boolean = true;
        documentFound: boolean = true;

        record: interfaces.IDocument = {
            id: parseInt(this.$routeParams.id)
        }

        static $inject: string[] = ["$location", "$q", "$routeParams", "documentDownloadService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: IDocumentViewParams,
            private documentDownloadService: services.IDocumentDownloadService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.widgetRefresh.cwnNewMeetingInvitationCount = true;

            let promises: ng.IPromise<any>[] = [
                this.documentDownloadService.readDocument(this.record.id)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results))
                .catch(() => {
                    this.formLoading = false;
                    this.documentFound = false;
                });
        }

        writeFormValues(document: interfaces.IDocument) {
            this.record = document;
            this.formLoading = false;
            this.documentFound = true;
        }
    }

    angular
        .module("app.documents")
        .controller("DocumentViewController", DocumentViewController);

}