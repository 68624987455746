namespace app.widgets {
	"use strict";

	interface ICwnButton {
		formSubmitting: string,
		submitClick: string,
		buttonName: string,
		iconSpinningClass: string,
		iconStaticClass: string
	}

	class CwnButton implements ng.IDirective {

		static instance(): ng.IDirective {
			return new CwnButton;
		}

        scope: ICwnButton = {
			formSubmitting: "=",
			submitClick: "&",
			buttonName: "@",
			iconSpinningClass: "@",
			iconStaticClass: "@"
		};

        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnButton/cwnButton.html";
	}

	angular
		.module("app.widgets")
		.directive("cwnButton", CwnButton.instance);
}