namespace blocks.kendoConfiguration {
    export interface IKendoListView {
        createDataSource(pageSize: number, searchFunction: any): kendo.data.DataSource;
        configure(templateId: string, dataSource: kendo.data.DataSource, dataBoundFunction: any);
	}

    class KendoListView implements IKendoListView {

        createDataSource(pageSize: number, searchFunction: any): kendo.data.DataSource {
            return new kendo.data.DataSource({
                pageSize: pageSize,
                serverPaging: true,
                transport: {
                    read: (options) => {
                        searchFunction(options.data.pageSize, options.data.page).then((data) => {
                            options.success(data);
                        });
                    }
                },
                schema: <kendo.data.DataSourceSchema> {
                    data: "results",
                    total: "numberRecords",
                }
            });
        }

        configure(templateId: string, dataSource: kendo.data.DataSource, dataBoundFunction: any): kendo.ui.ListViewOptions {
            var templateName = "#" + templateId;

            return <kendo.ui.ListViewOptions>{
                template: kendo.template($(templateName).html()),
                dataSource: dataSource,
                dataBound: (e: kendo.ui.ListViewEvent) => {
                    dataBoundFunction(e.sender.dataSource.data().length === 0)
                }
            }
        }
	}

	angular
		.module("blocks.kendoConfiguration")
		.service("kendoListView", KendoListView);
}