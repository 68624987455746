namespace app.documents {
    "use strict";

    export interface IDocumentCategoryConstants {
        financeDocuments: number;
        homepageDocuments: number;
        operatingRulesDocuments: number;
        //procedures: number;
        newsletterDocuments: number;
    }

    angular
        .module("app.documents")
        .constant("documentCategoryConstants", <IDocumentCategoryConstants>{
            financeDocuments: 61,
            homepageDocuments: 6,
            operatingRulesDocuments: 8,
            //procedures: 2192,
            newsletterDocuments: 7
        });
}
