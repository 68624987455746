namespace app.subscribers.services {
	"use strict";

	export interface ICompanyService {
		createRecord(record: interfaces.ICompany): ng.IPromise<interfaces.ICompany>;
	}

	class CompanyService extends app.core.services.BaseService implements ICompanyService {
		private serviceBase: string = "/api/company/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		createRecord(record: interfaces.ICompany): ng.IPromise<interfaces.ICompany> {
			return this.$http
				.post(this.serviceBase + "create", record)
				.then((response: ng.IHttpPromiseCallbackArg<interfaces.ICompany>): interfaces.ICompany => response.data)
				.catch((error) => this.logError(error));
		};

	}

	angular
		.module("app.subscribers.services")
		.service("companyService", CompanyService);
}