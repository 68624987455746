namespace app.core {
	angular
		.module("app.core.angularConfiguration")
		.config(configure);

	configure.$inject = ["$compileProvider", "$httpProvider", "$logProvider"];
    function configure(
        $compileProvider: ng.ICompileProvider,
        $httpProvider: ng.IHttpProvider,
        $logProvider: ng.ILogProvider) {

        if ($logProvider.debugEnabled) {
            $logProvider.debugEnabled(true);
        }

        $compileProvider.debugInfoEnabled(false);

        $httpProvider.interceptors.push("apiInterceptorService");
    }
}