namespace app.widgets {
	"use strict";

    interface ICwnDocumentArchiveHeadingVm {
        heading: string;
        archivedText: string;
	}

	class CwnDocumentArchiveHeadingController implements ICwnDocumentArchiveHeadingVm {
        heading: string;
        archivedText: string;
        archived: boolean;

        static $inject: string[] = ["$scope"];
        constructor(private $scope: ng.IScope) {
			this.$scope.$watch(
				() => this.archived,
                (archived) => {
                    if (archived === true) {
                        this.archivedText = " - archive section";
                    } else {
                        this.archivedText = "";
                    }
				});
		}
	}

    interface IDirectiveScope {
        archived: string;
        heading: string;
    }

	class CwnDocumentArchiveHeading implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnDocumentArchiveHeading;
		}

        scope: IDirectiveScope = {
            archived: "=",
            heading: "="
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnDocumentArchiveHeading/cwnDocumentArchiveHeading.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;

        controller = CwnDocumentArchiveHeadingController;
    }

	angular
		.module("app.widgets")
		.directive("cwnDocumentArchiveHeading", CwnDocumentArchiveHeading.instance);
}