namespace app.core.services {
	"use strict";

	export abstract class BaseService {
        constructor(
			public $http: ng.IHttpService,
			public $q: ng.IQService,
			public notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
        }

        logError(error) {
			this.notificationLoggingService.logServiceError(error);

			return this.$q.reject(error.data.details);
        }
	}
}