namespace app.workingGroups {
    "use strict";

    interface IDirectiveScope {
        workingGroups: string;
    }

    class CwnSubGroupsMenuDetail implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnSubGroupsMenuDetail;
        }

        scope: IDirectiveScope = {
            workingGroups: "="
        };
        restrict: string = "E";
        templateUrl: string = "/app/workingGroups/widgets/cwnSubGroupsMenuDetail/cwnSubGroupsMenuDetail.html";
    }

    angular
        .module("app.workingGroups")
        .directive("cwnSubGroupsMenuDetail", CwnSubGroupsMenuDetail.instance);
}