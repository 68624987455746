namespace app.publicPages {
    "use strict";

    interface IRegisterYourDetailsStage3Vm {
    }

    class RegisterYourDetailsStage3Controller implements IRegisterYourDetailsStage3Vm {

    }

    angular
        .module("app.publicPages")
        .controller("RegisterYourDetailsStage3Controller", RegisterYourDetailsStage3Controller);
}