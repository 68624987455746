namespace app.pages {
	"use strict";

	class LogoutController {

		static $inject: string[] = ["$location", "userCredentials"];
		constructor(
			private $location: ng.ILocationService,
			private userCredentials: app.core.security.IUserCredentials) {
			userCredentials.logout();
           	$location.path("/login");
		}
	}

	angular
		.module("app.pages")
		.controller("LogoutController", LogoutController);
}