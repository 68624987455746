namespace app.core.security.services {
	"use strict";

	export interface IRegisterClientService {
		registerClient(): ng.IPromise<IClientDetails>;
	}

	class RegisterClientService extends app.core.services.BaseService implements IRegisterClientService {
		private serviceBase: string = "/api/registerclient/";

        static $inject: string[] = ["$http", "$q", "registerClientConstant", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			private registerClientConstant: string,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		registerClient(): ng.IPromise<IClientDetails> {
			let record = {
				value: this.registerClientConstant
			};

			return this.$http
				.put(this.serviceBase + "register", record)
				.then((response: ng.IHttpPromiseCallbackArg<IClientDetails>): IClientDetails => this.httpCallComplete(response))
				.catch((error) => this.logError(error));
		}

        httpCallComplete(response: ng.IHttpPromiseCallbackArg<IClientDetails>): IClientDetails
        {
            return response.data;
        }
	}

	angular
		.module("app.core.security.services")
		.constant("registerClientConstant", "SomeSecureValueSubscribers")
		.service("registerClientService", RegisterClientService);
}
