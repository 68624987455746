namespace app.publicPages {
	angular
		.module("app.meetings")
		.config(routeConfig);

	routeConfig.$inject = ["$routeProvider", "claimsConstants"];
	function routeConfig($routeProvider: ng.route.IRouteProvider, claimsConstants: app.core.security.IClaimsConstants): void {
		$routeProvider
			.when("/diarydates", <app.core.router.ICwnRoute>{
				templateUrl: "/app/meetings/diaryDates/diaryDates.html",
				controller: "DiaryDatesController",
				controllerAs: "vm",
                title: "Diary dates",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/meetings", <app.core.router.ICwnRoute>{
				templateUrl: "/app/meetings/meetingList/meetingList.html",
				controller: "MeetingListController",
				controllerAs: "vm",
                title: "Meetings",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/meeting/attendees/:id", <app.core.router.ICwnRoute>{
				templateUrl: "/app/meetings/meetingAttendees/meetingAttendees.html",
				controller: "MeetingAttendeesController",
				controllerAs: "vm",
                title: "Meeting attendees",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/meeting/response/:id", <app.core.router.ICwnRoute>{
				templateUrl: "/app/meetings/meetingResponse/meetingResponse.html",
				controller: "MeetingResponseController",
				controllerAs: "vm",
                title: "Meeting response",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
            ;
	}
}