namespace app.pages {
    "use strict";

    interface IDocumentTypeVm {
        documentType: documents.interfaces.IDocumentType;
        formLoading: boolean;
        archived: boolean;
        bindData: boolean;
        refreshData: boolean;
        documentArchivedClick(): void;
    }

    class DocumentTypeController implements IDocumentTypeVm {
		documentType: documents.interfaces.IDocumentType = <documents.interfaces.IDocumentType> {
			id: parseInt(this.$routeParams.id)
		};

        formLoading: boolean = true;
        archived: boolean = false;
        bindData: boolean = false;
        refreshData: boolean = false;

        static $inject: string[] = ["$location", "$q", "$routeParams", "documentService", "documentTypeService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
			private $routeParams: app.core.interfaces.IEditRouteParams,
            private documentService: documents.services.IDocumentService,
            private documentTypeService: documents.services.IDocumentTypeService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;

            let promises: ng.IPromise<any>[] = [
                this.documentTypeService.readRecord(this.documentType.id)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(documentType: documents.interfaces.IDocumentType) {
            this.documentType = documentType;
            this.formLoading = false;
        }

        documentArchivedClick() {
            this.archived = !this.archived;
            this.refreshData = true;
        }

        searchData = (recordsPerPage: number, pageNumber: number) => {
            var searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument> = {
                recordsPerPage: recordsPerPage,
                pageNumber: pageNumber,
                filter: {
                    id: 0,
                    archived: this.archived
                }
            };

            return this
                .documentService
                .readByDocumentTypeKey(this.documentType.id, searchParameters);
         }
    }

    angular
        .module("app.pages")
        .controller("DocumentTypeController", DocumentTypeController);

}