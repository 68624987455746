namespace app.core.security.services {
	"use strict";

	export interface ILoginRedirectService {
        redirectPostLogin(): void;
        redirectPath(path: string): void;
	}

    class LoginRedirectService implements ILoginRedirectService {
        lastPath: string = "/";

        static $inject: string[] = ["$location"];
        constructor(private $location: ng.ILocationService) { }

        redirectPostLogin(): void {
            this.$location.path(this.lastPath);
            this.lastPath = "/";
        }

        redirectPath(path: string): void {
            this.lastPath = path;
            this.$location.path("/login");
        }
	}

	angular
		.module("app.core.security.services")
		.service("loginRedirectService", LoginRedirectService);
}
