namespace app.widgets {
    "use strict";

    interface IDirectiveScope {
        workingGroups: string;
    }

    class CwnMenuGroupsDetail implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnMenuGroupsDetail;
        }

        scope: IDirectiveScope = {
            workingGroups: "="
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnMenuGroupsDetail/cwnMenuGroupsDetail.html";
    }

    angular
        .module("app.widgets")
        .directive("cwnMenuGroupsDetail", CwnMenuGroupsDetail.instance);
}