namespace app.core.security.services {
	"use strict";

	export interface IRefreshTokenService {
		refreshToken(): ng.IPromise<void>;
	}

	class RefreshTokenService extends app.core.services.BaseService implements IRefreshTokenService {
		private serviceBase: string = "/token";

		static $inject: string[] = ["$http", "$q", "userCredentials", "notificationLoggingService"];
		constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			private userCredentials: IUserCredentials,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
			}

		refreshToken(): any {
			if (this.userCredentials.accessTokenExpired() === true) {
				let clientDetails: IClientDetails = this.userCredentials.getClientDetails();
				let refreshTokenDetails = "grant_type=refresh_token&client_id=" + clientDetails.clientId + "&client_secret=" + clientDetails.clientIdSecret + "&refresh_token=" + this.userCredentials.getRefreshToken();

				return this.$http
					.post(this.serviceBase, refreshTokenDetails)
					.then((response: ng.IHttpPromiseCallbackArg<ITokens>): void => this.httpCallComplete(response))
					.catch((error) => this.logError(error));
			} else {
				return this.$q.when();
			}
		}

        httpCallComplete(response: ng.IHttpPromiseCallbackArg<ITokens>): void
        {
			let successfulLoginDetails: ISuccessfulLoginDetails = {
				tokens: response.data,
				clientDetails: {
					clientId: response.headers("subscriber-entry-details-1"),
					clientIdSecret: response.headers("subscriber-entry-details-2")
				},
				claims: response.headers("subscriber-entry-details-3"),
				accessTokenExpirationMinutes: parseInt(response.headers("subscriber-entry-details-4"), 10),
				loggedInUser: response.headers("subscriber-entry-details-5"),
				storeLoginDetails: this.userCredentials.getStoreLoginDetails()
			}

			this.userCredentials.store(successfulLoginDetails, successfulLoginDetails.storeLoginDetails);
        }
	}

	angular
		.module("app.core.security.services")
		.service("refreshTokenService", RefreshTokenService);
}