(function () {
    "use strict";

    angular.module("app.widgets")
        .directive("cwnCompileHtml", cwnCompileHtml);

    cwnCompileHtml.$inject = ["$compile"];
    function cwnCompileHtml($compile) {

        var directive = {
            scope: {
                content: "="
            },
            restrict: "E",
            replace: true,
            link: function (scope, ele, attrs) {
                scope.$watch("content", function (html) {
                    if (html) {
                        html = html.replace(/ href=/g, " cwn-secure-document href=");

                        ele.html(html);
                        $compile(ele.contents())(scope);
                    }
                });
            }
        };
        return directive;
    }
})();