namespace blocks.kendoConfiguration {
	export interface IKendoPager {
		configure(dataSource: kendo.data.DataSource): kendo.ui.PagerOptions;
	}

	class KendoPager implements IKendoPager {
		configure(dataSource: kendo.data.DataSource): kendo.ui.PagerOptions {
            return <kendo.ui.PagerOptions>{
                pageSizes: false,
                dataSource: dataSource,
                buttonCount: 5,
                info: true,
                input: false,
                numeric: true,
                previousNext: true
			};
		}
	}

	angular
		.module("blocks.kendoConfiguration")
		.service("kendoPager", KendoPager);
}