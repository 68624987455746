namespace app.widgets {
    "use strict";

    interface IDirectiveScope {
    }

    class CwnMenuGeneral implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnMenuGeneral;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnMenuGeneral/cwnMenuGeneral.html";
    }

    angular
        .module("app.widgets")
        .directive("cwnMenuGeneral", CwnMenuGeneral.instance);
}