namespace app.meetings {
    "use strict";

    interface IMeetingAttendeesVm {
        formLoading: boolean;
        meeting: interfaces.IMeeting;
        attendees: interfaces.IGetReadMeetingAttendeesReturnType[];
    }

    class MeetingAttendeesController implements IMeetingAttendeesVm {
        formLoading: boolean = true;
        meeting: interfaces.IMeeting = {
			id: parseInt(this.$routeParams.id)
		};
        attendees: interfaces.IGetReadMeetingAttendeesReturnType[];

        static $inject: string[] = ["$location", "$q", "$routeParams", "meetingService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
			private $routeParams: app.core.interfaces.IEditRouteParams,
            private meetingService: services.IMeetingService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;

            let promises: ng.IPromise<any>[] = [
                this.meetingService.readRecord(this.meeting.id),
                this.meetingService.readMeetingAttendees(this.meeting.id)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(meeting: interfaces.IMeeting, attendees: interfaces.IGetReadMeetingAttendeesReturnType[]) {
            this.meeting = meeting;
            this.attendees = attendees;
            this.formLoading = false;
        }
    }

    angular
        .module("app.meetings")
        .controller("MeetingAttendeesController", MeetingAttendeesController);

}