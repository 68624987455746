namespace app.widgets {
    "use strict";

    interface IDirectiveScope {
        formLoading: string;
    }

    class CwnFormLoading implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnFormLoading;
        }

        scope: IDirectiveScope = {
            formLoading: "="
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnFormLoading/cwnFormLoading.html";

        replace: boolean = true;
    }

    angular
        .module("app.widgets")
        .directive("cwnFormLoading", CwnFormLoading.instance);
}