namespace app.widgets {
    "use strict";

    interface ICwnNewMeetingInvitationCountVm {
        numberMeetings: number;
	}

	class CwnNewMeetingInvitationCountController implements ICwnNewMeetingInvitationCountVm {
        numberMeetings: number;

		static $inject: string[] = ["$scope", "meetingService", "widgetRefresh"];
        constructor(
            private $scope: ng.IScope,
            private meetingService: meetings.services.IMeetingService,
            private widgetRefresh: IWidgetRefreshValues) {

			this.$scope.$watch(
				() => this.widgetRefresh.cwnNewMeetingInvitationCount,
				(refresh) => {
					if (refresh === true) {
                        this.readNewMeetingInvitations();
					}
				});
        }

        readNewMeetingInvitations() {
            this.meetingService
                .readNewMeetingInvitations()
                .then((numberMeetings: number) => {
                    this.widgetRefresh.cwnNewMeetingInvitationCount = false;
                    this.numberMeetings = numberMeetings;
                });
        }
    }

    interface IDirectiveScope {
    }

    class CwnNewMeetingInvitationCount implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnNewMeetingInvitationCount;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnNewMeetingInvitationCount/cwnNewMeetingInvitationCount.html";
		controller = CwnNewMeetingInvitationCountController;
		controllerAs: string = "vm";
		bindToController: boolean = true;
    }

    angular
        .module("app.widgets")
        .directive("cwnNewMeetingInvitationCount", CwnNewMeetingInvitationCount.instance);
}