namespace app.publicPages {
	"use strict";

	angular
		.module("app.documents")
		.config(routeConfig);

	routeConfig.$inject = ["$routeProvider", "claimsConstants"];
	function routeConfig($routeProvider: ng.route.IRouteProvider, claimsConstants: app.core.security.IClaimsConstants): void {
		$routeProvider
			.when("/documentsearch/:documentSearchText?", <app.core.router.ICwnRoute>{
				templateUrl: "/app/documents/documentSearch/documentSearch.html",
				controller: "DocumentSearchController",
				controllerAs: "vm",
                title: "Document search",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/documentview/:id", <app.core.router.ICwnRoute>{
				templateUrl: "/app/documents/documentView/documentView.html",
				controller: "DocumentViewController",
				controllerAs: "vm",
                title: "Document",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/documentviewbyfilename/:fileName", <app.core.router.ICwnRoute>{
				templateUrl: "/app/documents/documentViewByFileName/documentViewByFileName.html",
				controller: "DocumentViewByFileNameController",
				controllerAs: "vm",
                title: "Document",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            });
	}
}