namespace app.widgets {
	"use strict";

    interface ICwnDocumentsVm {
        kendoPagerOptions: kendo.ui.PagerOptions;
        kendoListViewOptions: kendo.ui.ListViewOptions;
        noResultsFound: boolean;
    }

    interface ICwnDocumentsScope extends ng.IScope {
        documentListView: kendo.ui.ListView;
    }

    class CwnDocumentsController implements ICwnDocumentsVm {
        bindData: boolean;
        refreshData: boolean;
        noResultsFound: boolean = false;
        kendoPagerOptions: kendo.ui.PagerOptions;
        kendoListViewOptions: kendo.ui.ListViewOptions;
        searchData: any;

        static $inject: string[] = ["$scope", "kendoListView", "kendoPager"];
        constructor(
            private $scope: ICwnDocumentsScope,
            private kendoListView: blocks.kendoConfiguration.IKendoListView,
            private kendoPager: blocks.kendoConfiguration.IKendoPager) {
			this.$scope.$watch(
				() => this.bindData,
                (bindData) => {
                    if (this.bindData === true) {
                        this.activate();
                    }
				});

			this.$scope.$watch(
				() => this.refreshData,
                (refreshData) => {
                    if (refreshData === true) {
                        this.refreshListView();
                    }
				});
        }

        activate() {
            var dataSource: kendo.data.DataSource = this.kendoListView.createDataSource(5, (recordsPerPage, pageNumber) => this.searchData()(recordsPerPage, pageNumber));

            this.kendoListViewOptions = this.kendoListView.configure("documentsTemplate", dataSource, (noResultsFound) => this.dataBound(noResultsFound));
            this.kendoPagerOptions = this.kendoPager.configure(dataSource);
        }

        dataBound(noResultsFound) {
            this.noResultsFound = noResultsFound;
        }

        refreshListView() {
            this.$scope.documentListView.dataSource.page(1);
            this.refreshData = false;
        }
	}

    interface IDirectiveScope {
        searchData: string;
        bindData: string;
        refreshData: string;
    }

	class CwnDocuments implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnDocuments;
		}

        scope: IDirectiveScope = {
            searchData: "&",
            bindData: "=",
            refreshData: "="
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnDocuments/cwnDocuments.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;

        controller = CwnDocumentsController;
    }

	angular
		.module("app.widgets")
		.directive("cwnDocuments", CwnDocuments.instance);
}