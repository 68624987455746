namespace app.workingGroups {
    "use strict";

    interface IWorkingGroupMeetingsVm {
        workingGroup: interfaces.IWorkingGroup;
        formLoading: boolean;
        bindData: boolean;
        noResultsFound: boolean;
        kendoPagerOptions: kendo.ui.PagerOptions;
        kendoListViewOptions: kendo.ui.ListViewOptions;
    }

    class WorkingGroupMeetingsController implements IWorkingGroupMeetingsVm {
        formLoading: boolean = true;
        bindData: boolean = false;
        noResultsFound: boolean = false;
        workingGroup: interfaces.IWorkingGroup;
        kendoPagerOptions: kendo.ui.PagerOptions;
        kendoListViewOptions: kendo.ui.ListViewOptions;

        record: interfaces.IWorkingGroup = {
            group: parseInt(this.$routeParams.group),
            subgroup: parseInt(this.$routeParams.subgroup),
            id: parseInt(this.$routeParams.subgroup || this.$routeParams.group)
        }

        static $inject: string[] = ["$location", "$q", "$routeParams", "meetingService", "workingGroupService", "kendoListView", "kendoPager", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: interfaces.IWorkingGroupRouteParams,
            private meetingService: meetings.services.IMeetingService,
            private workingGroupService: services.IWorkingGroupService,
            private kendoListView: blocks.kendoConfiguration.IKendoListView,
            private kendoPager: blocks.kendoConfiguration.IKendoPager,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            var dataSource: kendo.data.DataSource = this.kendoListView.createDataSource(5, (recordsPerPage, pageNumber) => this.searchData(recordsPerPage, pageNumber));

            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;

            this.kendoListViewOptions = this.kendoListView.configure("workingGroupMeetingTemplate", dataSource, (noResultsFound) => this.dataBound(noResultsFound));
            this.kendoPagerOptions = this.kendoPager.configure(dataSource);

            let promises: ng.IPromise<any>[] = [
                this.workingGroupService.readRecord(this.record.id)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(workingGroup: interfaces.IWorkingGroup) {
            this.workingGroup = workingGroup;
            this.formLoading = false;
        }

        dataBound(noResultsFound) {
            this.noResultsFound = noResultsFound;
            this.formLoading = false;
        }

        searchData(recordsPerPage: number, pageNumber: number) {
            var pagingParameters: core.interfaces.IPagingParameters = {
                recordsPerPage: recordsPerPage,
                pageNumber: pageNumber
            };

            return this.meetingService.readAllByWorkingGroupKey(this.record.id, pagingParameters);
         }
    }

    angular
        .module("app.workingGroups")
        .controller("WorkingGroupMeetingsController", WorkingGroupMeetingsController);

}