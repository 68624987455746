namespace app.pages {
    "use strict";

    interface IDocumentSearchVm {
        formLoading: boolean;
        documentSearchText: string;
        bindData: boolean;
        refreshData: boolean;
        submitClick(invalidForm: boolean): void;
    }

    interface IDocumentSearchRouteParams extends ng.route.IRouteParamsService {
        documentSearchText: string;
    }

    class DocumentSearchController implements IDocumentSearchVm {
        formLoading: boolean = true;
        bindData: boolean = false;
        refreshData: boolean = false;

        documentSearchText = this.$routeParams.documentSearchText;

        static $inject: string[] = ["$location", "$q", "$routeParams", "documentService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
     	    private $routeParams: IDocumentSearchRouteParams,
            private documentService: documents.services.IDocumentService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;
            this.formLoading = false;
        }

        searchData = (recordsPerPage: number, pageNumber: number) => {
            var searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument> = {
                recordsPerPage: recordsPerPage,
                pageNumber: pageNumber,
                filter: {
                    id: 0,
                    archived: false,
                    name: this.documentSearchText
                }
            };

            return this
                .documentService
                .searchRecords(searchParameters);
        }

		submitClick(invalidForm: boolean): void {
			if (invalidForm === false) {
                this.refreshData = true;
			}
		}
    }

    angular
        .module("app.documents")
        .controller("DocumentSearchController", DocumentSearchController);

}