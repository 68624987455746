namespace app.documents {
    "use strict";

    interface IDocumentViewByFileNameVm {
        formLoading: boolean;
        record: interfaces.IDocument;
    }

	export interface IDocumentViewByFileNameParams extends ng.route.IRouteParamsService {
        fileName: string;
	}

    class DocumentViewByFileNameController implements IDocumentViewByFileNameVm {
        formLoading: boolean = true;
        record: interfaces.IDocument;

        static $inject: string[] = ["$location", "$q", "$routeParams", "documentDownloadService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: IDocumentViewByFileNameParams,
            private documentDownloadService: services.IDocumentDownloadService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.widgetRefresh.cwnNewMeetingInvitationCount = true;

            let promises: ng.IPromise<any>[] = [
                this.documentDownloadService.readDocumentByFileName(this.$routeParams.fileName)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results))
                .catch(() => {
                    this.formLoading = false;
                });
        }

        writeFormValues(document: interfaces.IDocument) {
            this.record = document;
            this.formLoading = false;
        }
    }

    angular
        .module("app.documents")
        .controller("DocumentViewByFileNameController", DocumentViewByFileNameController);

}