namespace app.meetings.services {
	"use strict";

    export interface IMeetingSubscriberLinkService {
        readByMeetingKey(recordKey: number): ng.IPromise<interfaces.IMeetingSubscriberLink>;
        updateRecord(recordKey: number, record: interfaces.IMeetingSubscriberLink): ng.IPromise<void>;
	}

	class MeetingSubscriberLinkService extends app.core.services.BaseService implements IMeetingSubscriberLinkService {
		private serviceBase: string = "/api/meetingsubscriberlink/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readByMeetingKey(recordKey: number): ng.IPromise<interfaces.IMeetingSubscriberLink> {
            return this.$http
                .get(this.serviceBase + "readbymeetingkey/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IMeetingSubscriberLink>): interfaces.IMeetingSubscriberLink => response.data)
                .catch((error) => this.logError(error));
        };

		updateRecord(recordKey: number, record: interfaces.IMeetingSubscriberLink): ng.IPromise<void> {
			return this.$http
				.put(this.serviceBase + "update/" + recordKey, record)
				.catch((error) => this.logError(error));
		};
	}

	angular
		.module("app.meetings.services")
		.service("meetingSubscriberLinkService", MeetingSubscriberLinkService);
}
