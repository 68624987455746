namespace blocks.errorLogging {
    "use strict";

    export interface IExceptionLoggingService {
        logException(exception: any, cause: any): void;
    }

    class ExceptionLoggingService implements IExceptionLoggingService {
        serviceBase: string = "/api/errorlogjavascriptexception/";

        static $inject: string[] = ["$log", "$window"];
        constructor(
            private $log: ng.ILogService,
            private $window: ng.IWindowService) { }

        logException(exception: Error, cause?: string): void {
            this.$log.error.apply(this.$log, arguments);
            try {
                var errorMessage = exception.name + ": " +  exception.message;

                StackTrace
                    .fromError(exception)
                    .then((stackframes) => {
                        var stringifiedStack = stackframes.map(function(sf) {
                            return sf.toString();
                        }).join('\n');

                        $.ajax({
                            type: "POST",
                            url: this.serviceBase + "create",
                            contentType: "application/json",
                            data: angular.toJson({
                                requestUri: this.$window.location.href,
                                message: errorMessage,
                                type: "Exception",
                                stackTraceDetails: stringifiedStack,
                                cause: (cause || "")
                            })
                        });
                    })
                    .catch((err) => {
                        console.log(err.message);
                    });
            } catch(loggingError) {
                this.$log.warn("Error server-side logging failed");
                this.$log.log(loggingError);
            }
        }
    }

    angular
        .module("blocks.errorLogging")
        .service("exceptionLoggingService", ExceptionLoggingService)
}