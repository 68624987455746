namespace app.pages.services {
	"use strict";

	export interface ILogoutService {
		logout(refreshToken: string): ng.IPromise<void>;
	}

	class LogoutService extends app.core.services.BaseService implements ILogoutService {
		private serviceBase: string = "/api/logout/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		logout(refreshToken: string): ng.IPromise<void> {
            return this.$http
                .get(this.serviceBase + "logout?refreshTokenId=" + refreshToken)
                .catch((error) => this.logError(error));
		};
	}

	angular
		.module("app.pages.services")
		.service("logoutService", LogoutService);
}