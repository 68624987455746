namespace app.meetings {
    "use strict";

    export interface IMeetingAttendanceStatusConstants {
        NoReply: number;
        Attending: number;
        NotAttending: number;
        AttendingByTeleconference: number;
    }

    angular
        .module("app.meetings")
        .constant("meetingAttendanceStatusConstants", <IMeetingAttendanceStatusConstants>{
            NoReply: 3,
            Attending: 1,
            NotAttending: 2,
            AttendingByTeleconference: 4
        });
}
