namespace app.core.security {
    "use strict";

    export interface IClaimsConstants {
        LoggedIn: string;
    }

    angular
        .module("app.core.security")
        .constant("claimsConstants", <IClaimsConstants>{
            LoggedIn: "0"
        });
}
