namespace app.widgets {
	"use strict";

    interface ICwnDocumentArchiveSectionVm {
        linkText: string;
	}

	class CwnDocumentArchiveSectionController implements ICwnDocumentArchiveSectionVm {
        linkText: string;
        archived: boolean;

        static $inject: string[] = ["$scope"];
        constructor(private $scope: ng.IScope) {

			this.$scope.$watch(
				() => this.archived,
                (archived) => {
                    if (archived === true) {
                        this.linkText = "Link to current documents section";
                    } else {
                        this.linkText = "Link to archive section";
                    }
				});
		}
	}

    interface IDirectiveScope {
        archived: string;
        linkClicked: string;
    }

	class CwnDocumentArchiveSection implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnDocumentArchiveSection;
		}

        scope: IDirectiveScope = {
            archived: "=",
            linkClicked: "&"
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnDocumentArchiveSection/cwnDocumentArchiveSection.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;

        controller = CwnDocumentArchiveSectionController;
    }

	angular
		.module("app.widgets")
		.directive("cwnDocumentArchiveSection", CwnDocumentArchiveSection.instance);
}