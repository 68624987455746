namespace app.core.security {
	"use strict";

	export interface IUserCredentials {
		store(accessDetails: ISuccessfulLoginDetails, rememberMyDetails: boolean): void;
		logout(): void;
		loggedIn: boolean;
		getAccessToken(): string;
		getRefreshToken(): string;
		getStoreLoginDetails(): boolean;
		getLoggedInUser(): string;
		getClientDetails(): IClientDetails;
		accessTokenExpired(): boolean;
		hasAnyClaim(requestedClaims: string[]): boolean;
	}

	class UserCredentials implements IUserCredentials {
		loggedIn: boolean = false;
		loginDetails: ISuccessfulLoginDetails
		claims: string[];
		menuOptions: any = {};

		static $inject: string[] = ["localStorage", "claimsConstants"];
		constructor(
			private localStorage: blocks.localStorage.ILocalStorage,
			private claimsConstants: app.core.security.IClaimsConstants) {

			this.loginDetails = localStorage.readValues() || this.initialiseLoginDetails();
			this.claims = this.loginDetails.claims.split(",");
			this.initialiseMenuOptionsToShow();
			this.calculateMenuOptionsToShow();
		}

		store(loginDetails: ISuccessfulLoginDetails, storeLoginDetails: boolean): void {
			this.loginDetails = loginDetails;
			this.loginDetails.storeLoginDetails = storeLoginDetails;
			this.claims = this.loginDetails.claims.split(",");
			this.initialiseMenuOptionsToShow();
			this.calculateMenuOptionsToShow();

			if (this.loginDetails.storeLoginDetails === true) {
				this.localStorage.saveValues(this.loginDetails);
			}
		}

		logout(): void {
			this.loginDetails = this.initialiseLoginDetails();
			this.localStorage.clearValues();
			this.loggedIn = false;
		}

		getAccessToken(): string {
			return this.loginDetails.tokens.access_token;
		}

		getRefreshToken(): string {
			return this.loginDetails.tokens.refresh_token;
		}

		getStoreLoginDetails(): boolean {
			return this.loginDetails.storeLoginDetails;
		}

		getClientDetails(): IClientDetails {
			return this.loginDetails.clientDetails;
		}

		getLoggedInUser(): string {
			return this.loginDetails.loggedInUser;
		}

		// Will the token expire in the next 10 minutes?
		accessTokenExpired(): boolean {
			return true;
            var currentTime = moment().add(600, "s");
			var tokenExpiration = moment.unix(this.loginDetails.accessTokenExpirationMinutes);

            return currentTime.isAfter(tokenExpiration);
		}

		private initialiseLoginDetails(): ISuccessfulLoginDetails {

			return <ISuccessfulLoginDetails> {
				tokens: {
					access_token: "",
					refresh_token: ""
				},
				clientDetails: {
					clientId: "",
					clientIdSecret: ""
				},
				claims: "",
				accessTokenExpirationMinutes: 0,
				loggedInUser: "",
				storeLoginDetails: false
			};
		}

        hasAnyClaim(requestedClaims: string[]): boolean {
            for (var requestedClaim in requestedClaims) {
                if (this.claims.indexOf(requestedClaims[requestedClaim]) !== -1) {
					this.loggedIn = true;
                    return true;
                }
            }

			this.loggedIn = false;
            return false;
        }

        calculateMenuOptionsToShow() {
            var claims = this.claims;

            for (var claim in claims) {
                if (claims.hasOwnProperty(claim)) {
                    this.menuOptions[claims[claim]] = true;
                }
            }
        }

        initialiseMenuOptionsToShow() {
            var claims = this.claimsConstants;

            for (var claim in claims) {
                if (claims.hasOwnProperty(claim)) {
                    this.menuOptions[claims[claim]] = false;
                }
            }
        }
	}

	angular
		.module("app.core.security")
		.service("userCredentials", UserCredentials);
}