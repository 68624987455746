namespace app.core.angularConfiguration {
	"use strict";

	angular
		.module("app.core.angularConfiguration")
		.run(appCoreRun);

	appCoreRun.$inject = ["$location", "$rootScope", "userCredentials", "loginRedirectService"];
	function appCoreRun(
		$location: ng.ILocationService,
		$rootScope: ng.IRootScopeService,
		userCredentials: app.core.security.IUserCredentials,
		loginRedirectService: app.core.security.services.ILoginRedirectService) {
		$rootScope.$on("$routeChangeStart", authorisedToViewPage);

		function authorisedToViewPage(event, next, current) {
			if (next.$$route) {
				if (next.$$route.requireAuthentication === true) {
                    if (userCredentials.hasAnyClaim(next.$$route.accessLevels) === false) {
						userCredentials.logout();
						loginRedirectService.redirectPath($location.path());
                    }
				}
			}
		}
	}
}