namespace app.meetings.services {
	"use strict";

    export interface IDiaryDatesService {
        readDiaryDates(): ng.IPromise<interfaces.IDiaryDateMonthYear[]>;
	}

	class DiaryDatesService extends app.core.services.BaseService implements IDiaryDatesService {
		private serviceBase: string = "/api/diarydate/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readDiaryDates(): ng.IPromise<interfaces.IDiaryDateMonthYear[]> {
            return this.$http
                .get(this.serviceBase + "readdiarydates")
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDiaryDateMonthYear[]>): interfaces.IDiaryDateMonthYear[] => response.data)
                .catch((error) => this.logError(error));
		};
	}

	angular
		.module("app.meetings.services")
		.service("diaryDatesService", DiaryDatesService);
}