namespace app.widgets {
	"use strict";

	interface ICwnHeaderPublicVm {
		loggedIn: boolean;
	}

	class CwnHeaderPublicController implements ICwnHeaderPublicVm {
		loggedIn: boolean;

		static $inject: string[] = ["$scope", "userCredentials"];
		constructor(private $scope: ng.IScope, private userCredentials: app.core.security.IUserCredentials) {
			this.loggedIn = this.userCredentials.loggedIn;

			this.$scope.$watch(
				() => this.userCredentials.loggedIn,
				(value) => this.loggedIn = value);
		}
	}

    interface IDirectiveScope {
    }

	class CwnHeaderPublic implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnHeaderPublic;
		}

		scope: IDirectiveScope = {
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnHeaderPublic/cwnHeaderPublic.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnHeaderPublicController;
	}

	angular
		.module("app.widgets")
		.directive("cwnHeaderPublic", CwnHeaderPublic.instance);
}