namespace app.core.security.services {
	"use strict";

	export interface ILoginService {
		login(clientDetails: IClientDetails, userCredentials: ILoginDetails): ng.IPromise<ISuccessfulLoginDetails>
	}

	class LoginService extends app.core.services.BaseService implements ILoginService {
		private serviceBase: string = "/token";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		login(clientDetails: IClientDetails, loginDetails: ILoginDetails): ng.IPromise<ISuccessfulLoginDetails> {
			let loginFormDetails = "grant_type=password&username=" + loginDetails.emailAddress
				+ "&password=" + encodeURI(loginDetails.password)
				+ "&client_id=" + clientDetails.clientId
				+ "&client_secret=" + clientDetails.clientIdSecret;

			return this.$http
				.post(this.serviceBase, loginFormDetails)
				.then((response: ng.IHttpPromiseCallbackArg<ITokens>): ISuccessfulLoginDetails => this.httpCallComplete(response))
                .catch((error) => {
                    if (error.status === 400) {
                        // No need to log the http error for an incorrect login.
                        return this.$q.reject(error.data.details);
                    } else {
                        this.logError(error);
                    }
                });
		}

        httpCallComplete(response: ng.IHttpPromiseCallbackArg<ITokens>): ISuccessfulLoginDetails
        {
			let successfulLoginDetails: ISuccessfulLoginDetails = {
				tokens: response.data,
				clientDetails: {
					clientId: response.headers("subscriber-entry-details-1"),
					clientIdSecret: response.headers("subscriber-entry-details-2")
				},
				claims: response.headers("subscriber-entry-details-3"),
				accessTokenExpirationMinutes: parseInt(response.headers("subscriber-entry-details-4"), 10),
				loggedInUser: response.headers("subscriber-entry-details-5"),
				storeLoginDetails: false
			}
            return successfulLoginDetails;
        }
	}

	angular
		.module("app.core.security.services")
		.service("loginService", LoginService);
}