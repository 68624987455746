namespace app.publicPages.services {
    "use strict";

    export interface IPasswordResetService {
        stage1(emailAddress: string): ng.IPromise<void>;
        stage2(tokenId: string): ng.IPromise<boolean>;
        stage3(tokenId: string, password: string): ng.IPromise<boolean>;
    }

	class PasswordResetService extends app.core.services.BaseService implements IPasswordResetService {
		private serviceBase: string = "/api/passwordreset/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

        stage1(emailAddress: string): ng.IPromise<void> {
            var record = {
                emailAddress: emailAddress
            }

            return this.$http
                .post(this.serviceBase + "stage1", record)
                .catch((error) => this.logError(error));
        }

        stage2(tokenId: string): ng.IPromise<boolean> {
            var record = {
                tokenId: tokenId
            }

            return this.$http
                .post(this.serviceBase + "stage2", record)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => response.data)
                .catch((error) => this.logError(error));
        }

        stage3(tokenId: string, password: string): ng.IPromise<boolean> {
            var record = {
                tokenId: tokenId,
                password: password
            }

            return this.$http
                .post(this.serviceBase + "stage3", record)
                .then((response: ng.IHttpPromiseCallbackArg<boolean>): boolean => response.data)
                .catch((error) => this.logError(error));
        }
    }

	angular
		.module("app.publicPages.services")
		.service("passwordResetService", PasswordResetService);
}