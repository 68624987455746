namespace blocks.localStorage {
	"use strict";

	export interface ILocalStorage {
		readValues(): any;
		saveValues(values: any): void;
		clearValues(): void;
	}

	class LocalStorage implements ILocalStorage {

		private localStorageKey: string = "loa-extranet";

		static $inject: string[] = ["$window"];
		constructor(private $window: ng.IWindowService) {}

		readValues(): any {
			var valuesString = this.$window.localStorage.getItem(this.localStorageKey);

			if (valuesString) {
				return JSON.parse(valuesString);
			} else {
				return null;
			}
		}

		saveValues(values: any): void {
            var valuesString = JSON.stringify(values);

            this.$window.localStorage.setItem(this.localStorageKey, valuesString);
		}

		clearValues(): void {
            this.$window.localStorage.removeItem(this.localStorageKey);
		}
	}

	angular
		.module("blocks.localStorage")
		.service("localStorage", LocalStorage);
}