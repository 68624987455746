namespace app.publicPages {
    "use strict";

    interface IRegisterYourDetailsStage1Vm {
        page: pages.interfaces.IPage;
        formLoading: boolean;
        formSubmitting: boolean;
        submitClick(invalidForm: boolean): void;
        agreeTermsAndConditions: boolean;
    }

    class RegisterYourDetailsStage1Controller implements IRegisterYourDetailsStage1Vm {
        page: pages.interfaces.IPage;
        formLoading: boolean = true;
        formSubmitting: boolean = false;
        agreeTermsAndConditions: boolean;

        static $inject: string[] = ["$location", "$q", "registerYourDetailsService"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private registerYourDetailsService: services.IRegisterYourDetailsService) {

            this.activate();
        }

        activate() {
            let promises: ng.IPromise<any>[] = [
                this.registerYourDetailsService.readTermsAndConditions()
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(page: pages.interfaces.IPage) {
            this.page = page;
            this.formLoading = false;
        }

        submitClick(invalidForm: boolean): void {
            if (invalidForm === false) {
                this.$location.path("/registeryourdetails/stage2");
            }
        };
    }

    angular
        .module("app.publicPages")
        .controller("RegisterYourDetailsStage1Controller", RegisterYourDetailsStage1Controller);

}