namespace app.core {
	"use strict";

	angular
		.module("app.core", [
			/*
			* Angular modules
			*/
			"ngAnimate", "ngRoute", "ngSanitize", "ngAria",
			/*
			* Our reusable cross app code modules
			*/
		 	"blocks.errorLogging", "blocks.localStorage", "blocks.kendoConfiguration",
			/*
			* Our core modules
			*/
			"app.core.angularConfiguration", "app.core.security",
			/*
			* 3rd Party modules
			*/
			"ui.bootstrap", "kendo.directives"
		]);
}