namespace app.documents.services {
	"use strict";

    export interface IDocumentTypeService {
        readRecord(recordKey: number): ng.IPromise<interfaces.IDocumentType>;
    }

	class DocumentTypeService extends app.core.services.BaseService implements IDocumentTypeService {
		private serviceBase: string = "/api/documenttype/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readRecord(recordKey: number): ng.IPromise<interfaces.IDocumentType> {
            return this.$http
                .get(this.serviceBase + "read/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocumentType>): interfaces.IDocumentType => response.data)
                .catch((error) => this.logError(error));
		};
    }

	angular
		.module("app.documents.services")
		.service("documentTypeService", DocumentTypeService);
}