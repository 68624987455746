namespace app.widgets {
    "use strict";

    interface IDirectiveScope {
    }

    class CwnMenuGeneralDetail implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnMenuGeneralDetail;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnMenuGeneralDetail/cwnMenuGeneralDetail.html";
    }

    angular
        .module("app.widgets")
        .directive("cwnMenuGeneralDetail", CwnMenuGeneralDetail.instance);
}