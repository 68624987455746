namespace app.widgets {
	"use strict";

	interface ICwnNavBarPublicVm {
		loggedIn: boolean;
	}

	class CwnNavBarPublicController implements ICwnNavBarPublicVm {
		loggedIn: boolean;

		static $inject: string[] = ["$scope", "userCredentials"];
		constructor(private $scope: ng.IScope, private userCredentials: app.core.security.IUserCredentials) {
			this.loggedIn = this.userCredentials.loggedIn;

			this.$scope.$watch(
				() => this.userCredentials.loggedIn,
				(value) => this.loggedIn = value);
		}
	}

    interface IDirectiveScope {
    }

	class CwnNavBarPublic implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnNavBarPublic;
		}

		scope: IDirectiveScope = {
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnNavBarPublic/cwnNavBarPublic.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnNavBarPublicController;
	}

	angular
		.module("app.widgets")
		.directive("cwnNavBarPublic", CwnNavBarPublic.instance);
}