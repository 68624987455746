namespace app.widgets {
	"use strict";

	interface ICwnNavBarMemberVm {
		loggedIn: boolean;
	}

	class CwnNavBarMemberController implements ICwnNavBarMemberVm {
		loggedIn: boolean;

		static $inject: string[] = ["$scope", "userCredentials"];
		constructor(private $scope: ng.IScope, private userCredentials: app.core.security.IUserCredentials) {
			this.loggedIn = this.userCredentials.loggedIn;

			this.$scope.$watch(
				() => this.userCredentials.loggedIn,
				(value) => this.loggedIn = value);
		}
	}

    interface IDirectiveScope {
    }

	class CwnNavBarMember implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnNavBarMember;
		}

		scope: IDirectiveScope = {
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnNavBarMember/cwnNavBarMember.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnNavBarMemberController;
	}

	angular
		.module("app.widgets")
		.directive("cwnNavBarMember", CwnNavBarMember.instance);
}