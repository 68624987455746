namespace app.widgets {
    "use strict";

    interface IDirectiveScope {
    }

    class CwnFooter implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnFooter;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnFooter/cwnFooter.html";

        replace: boolean = true;
    }

    angular
        .module("app.widgets")
        .directive("cwnFooter", CwnFooter.instance);
}