namespace app.widgets {
	"use strict";

    interface ICwnDocumentDownloadVm {
        displayDocument(): void;
    }

    class CwnDocumentDownloadController extends core.services.BaseService implements ICwnDocumentDownloadVm {
        recordKey: number;

        static $inject: string[] = ["$http", "$q", "$window", "notificationLoggingService"];
        constructor(
            $http: ng.IHttpService,
            $q: ng.IQService,
            private $window: ng.IWindowService,
            public notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
            super($http, $q, notificationLoggingService);
        }

        displayDocument() {
            this.readDocument(this.recordKey)
                .then((documentToken: documents.interfaces.IDocumentTokenReturnDetails) => {
                    this.$window.location.href = "/api/readdocument/downloadfile?documenttokenid=" + documentToken.documentTokenId + "&relativedirectoryfilepath=" + documentToken.requestedFile;
                });
        }

        private readDocument(documentKey: number): ng.IPromise<documents.interfaces.IDocumentTokenReturnDetails> {
            return this.$http
                .get("/api/documentdownload/viewdocument/" + this.recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<documents.interfaces.IDocumentTokenReturnDetails>): documents.interfaces.IDocumentTokenReturnDetails => response.data)
                .catch((error) => this.logError(error));
        };
    }

	interface IDirectiveScope {
        recordKey: string;
	}

	class CwnDocumentDownload implements ng.IDirective {

		static instance(): ng.IDirective {
			return new CwnDocumentDownload;
		}

        scope: IDirectiveScope = {
            recordKey: "=",
		};

        restrict: string = "E";
        transclude: boolean = true;
        templateUrl: string = "/app/widgets/cwnDocumentDownload/cwnDocumentDownload.html";
        replace: boolean = true;
        bindToController: boolean = true;
        controllerAs: string = "vm";
        controller = CwnDocumentDownloadController;
	}

	angular
		.module("app.widgets")
		.directive("cwnDocumentDownload", CwnDocumentDownload.instance);
}