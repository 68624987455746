namespace app.publicPages {
    "use strict";

    interface IPasswordResetStage1Vm {
        formSubmitting: boolean;
        submitClick(invalidForm: boolean): void;
        emailAddress: string;
        displayFurtherInstructions: boolean;
    }

    class PasswordResetStage1Controller implements IPasswordResetStage1Vm {
        displayFurtherInstructions: boolean = false;
        formSubmitting: boolean = false;
        emailAddress: string;

        static $inject: string[] = ["passwordResetService"];
        constructor(
            private passwordResetService: services.IPasswordResetService) {
        }

        submitClick(invalidForm: boolean): void {
            if (invalidForm === false) {
                this.saveRecord();
            }
        };

        saveRecord(): void {
            this.formSubmitting = true;

            this
                .passwordResetService
                .stage1(this.emailAddress)
                .then(() => {
                    this.formSubmitting = false;
                    this.displayFurtherInstructions = true;
                });
        }
    }

    angular
        .module("app.publicPages")
        .controller("PasswordResetStage1Controller", PasswordResetStage1Controller);

}