namespace app.workingGroups {
    "use strict";

    interface IWorkingGroupOverviewVm {
        workingGroup: interfaces.IWorkingGroup;
        meetings: interfaces.IMeeting[];
        formLoading: boolean;
        bindData: boolean;
    }

    class WorkingGroupOverviewController implements IWorkingGroupOverviewVm {
        formLoading: boolean = true;
        bindData: boolean = false;
        workingGroup: interfaces.IWorkingGroup;
        meetings: interfaces.IMeeting[];
        record: interfaces.IWorkingGroup = {
            group: parseInt(this.$routeParams.group),
            subgroup: parseInt(this.$routeParams.subgroup),
            id: parseInt(this.$routeParams.subgroup || this.$routeParams.group)
        }

        static $inject: string[] = ["$location", "$q", "$routeParams", "meetingService", "workingGroupService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: interfaces.IWorkingGroupRouteParams,
            private meetingService: meetings.services.IMeetingService,
            private workingGroupService: services.IWorkingGroupService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;

            let promises: ng.IPromise<any>[] = [
                this.workingGroupService.readRecord(this.record.id),
                this.meetingService.readUpcomingMeetingsByWorkingGroupKey(this.record.id)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(workingGroup: interfaces.IWorkingGroup, meetings: interfaces.IMeeting[]) {
            this.workingGroup = workingGroup;
            this.meetings = meetings;
            this.formLoading = false;
        }
    }

    angular
        .module("app.workingGroups")
        .controller("WorkingGroupOverviewController", WorkingGroupOverviewController);

}