namespace app.workingGroups {
    "use strict";

    interface ICwnSubGroupsMenuVm {
        workingGroups: interfaces.IWorkingGroup[];
    }

	class CwnSubGroupsMenuController implements ICwnSubGroupsMenuVm {
        workingGroupKey: number;
        workingGroups: interfaces.IWorkingGroup[];
        bindData: boolean = false;

		static $inject: string[] = ["$scope", "workingGroupService"];
        constructor(
            private $scope: ng.IScope,
            private workingGroupService: workingGroups.services.IWorkingGroupService) {

            this.$scope.$watch(
                () => this.bindData,
                (formBind) => {
                    if (formBind == true) {
                        this.activate();
                    }
                });

        }

        activate() {
            this.workingGroupService
                .readSubGroups(this.workingGroupKey)
                .then((workingGroups: interfaces.IWorkingGroup[]) => {
                    this.workingGroups = workingGroups;
                });
        }
    }

    interface IDirectiveScope {
        workingGroupKey: string;
        bindData: string;
    }

    class CwnSubGroupsMenu implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnSubGroupsMenu;
        }

        scope: IDirectiveScope = {
            bindData: "=",
            workingGroupKey: "="
        };
        restrict: string = "E";
        templateUrl: string = "/app/workingGroups/widgets/cwnSubGroupsMenu/cwnSubGroupsMenu.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnSubGroupsMenuController;
    }

    angular
        .module("app.workingGroups")
        .directive("cwnSubGroupsMenu", CwnSubGroupsMenu.instance);
}