namespace app.documents.services {
	"use strict";

    export interface IDocumentDownloadService {
        readDocument(documentKey: number): ng.IPromise<interfaces.IDocument>;
		readDocumentByFileName(documentFileName: string): ng.IPromise<interfaces.IDocument>;
    }

	class DocumentDownloadService extends app.core.services.BaseService implements IDocumentDownloadService {
		private serviceBase: string = "/api/documentdownload/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readDocument(documentKey: number): ng.IPromise<interfaces.IDocument> {
            return this.$http
                .get(this.serviceBase + "readdocument/" + documentKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocument>): interfaces.IDocument => response.data)
                .catch((error) => this.logError(error));
		};

		readDocumentByFileName(documentFileName: string): ng.IPromise<interfaces.IDocument> {
            return this.$http
                .get(this.serviceBase + "readdocumentbyfilename?fileName=" + documentFileName)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IDocument>): interfaces.IDocument => response.data)
                .catch((error) => this.logError(error));
		};
    }

	angular
		.module("app.documents.services")
		.service("documentDownloadService", DocumentDownloadService);
}