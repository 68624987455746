namespace app.widgets {
	"use strict";

	interface ICwnButton {
		formSubmitting: string,
		submitClick: string
	}

	class CwnButtonSubmit implements ng.IDirective {

		static instance(): ng.IDirective {
			return new CwnButtonSubmit;
		}

        scope: ICwnButton = {
			formSubmitting: "=",
			submitClick: "&"
		};

        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnButtonSubmit/cwnButtonSubmit.html";
	}

	angular
		.module("app.widgets")
		.directive("cwnButtonSubmit", CwnButtonSubmit.instance);
}