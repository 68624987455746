namespace app.workingGroups {
	angular
		.module("app.workingGroups")
		.config(routeConfig);

	routeConfig.$inject = ["$routeProvider", "claimsConstants"];
	function routeConfig($routeProvider: ng.route.IRouteProvider, claimsConstants: app.core.security.IClaimsConstants): void {
		$routeProvider
			.when("/group/overview/:group/:subgroup?", <app.core.router.ICwnRoute>{
				templateUrl: "/app/workingGroups/overview/overview.html",
				controller: "WorkingGroupOverviewController",
				controllerAs: "vm",
                title: "Working group",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/group/members/:group/:subgroup?", <app.core.router.ICwnRoute>{
				templateUrl: "/app/workingGroups/members/members.html",
				controller: "WorkingGroupMembersController",
				controllerAs: "vm",
                title: "Working group",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/group/meetings/:group/:subgroup?", <app.core.router.ICwnRoute>{
				templateUrl: "/app/workingGroups/meetings/meetings.html",
				controller: "WorkingGroupMeetingsController",
				controllerAs: "vm",
                title: "Working group",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/group/documents/:group/:subgroup?", <app.core.router.ICwnRoute>{
				templateUrl: "/app/workingGroups/documents/documents.html",
				controller: "WorkingGroupDocumentsController",
				controllerAs: "vm",
                title: "Working group",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
			.when("/group/restricted-documents/:group/:subgroup?", <app.core.router.ICwnRoute>{
				templateUrl: "/app/workingGroups/documentsRestricted/documentsRestricted.html",
				controller: "WorkingGroupDocumentsRestrictedController",
				controllerAs: "vm",
                title: "Working group",
                accessLevels: [claimsConstants.LoggedIn],
				requireAuthentication: true
            })
            ;
	}
}