namespace app.pages {
    "use strict";

    interface ICwnDocumentsMenuVm {
    }

	class CwnDocumentsMenuController implements ICwnDocumentsMenuVm {

		static $inject: string[] = [];
        constructor() {
        }
    }

    interface IDirectiveScope {
    }

    class CwnDocumentsMenu implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnDocumentsMenu;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/pages/widgets/cwnDocumentsMenu/cwnDocumentsMenu.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnDocumentsMenuController;
    }

    angular
        .module("app.pages")
        .directive("cwnDocumentsMenu", CwnDocumentsMenu.instance);
}