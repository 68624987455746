namespace app.pages {
    "use strict";

    interface ICwnMyGroupsVm {
        workingGroups: workingGroups.interfaces.IWorkingGroup[];
        formatURL(workingGroupKey: number, parentKey: number): string | number;
    }

	class CwnMyGroupsController implements ICwnMyGroupsVm {
        workingGroups: workingGroups.interfaces.IWorkingGroup[];
        bindData: boolean;

		static $inject: string[] = ["$scope", "workingGroupService"];
        constructor(
            private $scope: ng.IScope,
            private workingGroupService: workingGroups.services.IWorkingGroupService) {

            this.$scope.$watch(
                () => this.bindData,
                (formBind) => {
                    if (formBind == true) {
                        this.readWorkingGroups();
                    }
                });

        }

        readWorkingGroups() {
            this.workingGroupService
                .readMyGroups()
                .then((workingGroups: workingGroups.interfaces.IWorkingGroup[]) => {
                    this.workingGroups = workingGroups;
                });
        }

        formatURL(workingGroupKey: number, parentKey: number): string | number {
            if (parentKey === 0) {
                return workingGroupKey;
            } else {
                return parentKey + "/" + workingGroupKey;
            }
        };
    }

    interface IDirectiveScope {
        bindData: string;
    }

    class CwnMyGroups implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnMyGroups;
        }

        scope: IDirectiveScope = {
            bindData: "="
        };
        restrict: string = "E";
        templateUrl: string = "/app/pages/widgets/cwnMyGroups/cwnMyGroups.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnMyGroupsController;
    }

    angular
        .module("app.pages")
        .directive("cwnMyGroups", CwnMyGroups.instance);
}