namespace app.publicPages {
    "use strict";

    interface IPasswordResetStage2Vm {
        formSubmitting: boolean;
        formLoading: boolean;
        password: string;
        validToken: boolean;
        passwordResetFailure: boolean;
        passwordResetSuccess: boolean;
        submitClick(invalidForm: boolean): void;
    }

	export interface IPasswordResetStage2RouteParams extends ng.route.IRouteParamsService {
		tokenId: string;
	}

    class PasswordResetStage2Controller implements IPasswordResetStage2Vm {
        formSubmitting: boolean = false;
        formLoading: boolean = true;
        password: string = "";
        validToken: boolean = false;
        passwordResetFailure: boolean = false;
        passwordResetSuccess: boolean = false;

        static $inject: string[] = ["$q", "$routeParams", "passwordResetService"];
        constructor(
            private $q: ng.IQService,
			private $routeParams: IPasswordResetStage2RouteParams,
            private passwordResetService: services.IPasswordResetService) {

            this.activate();
        }

        activate() {
            let promises: ng.IPromise<any>[] = [
                this.passwordResetService.stage2(this.$routeParams.tokenId)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(validToken: boolean) {
            this.validToken = validToken;
            this.formLoading = false;
        }

        submitClick(invalidForm: boolean): void {
            if (invalidForm === false) {
                this.saveRecord();
            }
        };

        saveRecord(): void {
            this.formSubmitting = true;

            this.passwordResetService
                .stage3(this.$routeParams.tokenId, this.password)
                .then((passwordResetSuccess: boolean) => {
                    this.formSubmitting = false;

                    if (passwordResetSuccess === true) {
                        this.passwordResetSuccess = true;
                    } else {
                        this.passwordResetFailure = true;
                    }
                });
        }
    }

    angular
        .module("app.publicPages")
        .controller("PasswordResetStage2Controller", PasswordResetStage2Controller);

}