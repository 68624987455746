namespace app.core.services {
	"use strict";

	export interface IReadAllService<T> {
		readAllRecords(): ng.IPromise<T[]>;
	}

	export abstract class ReadAllService<T> extends BaseService implements IReadAllService<T> {
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService,
			private serviceBase: string) {
			super($http, $q, notificationLoggingService);
        }

		readAllRecords(): ng.IPromise<T[]> {
			return this.$http
				.get(this.serviceBase + "readall")
				.then((response: ng.IHttpPromiseCallbackArg<T[]>): T[] => response.data)
				.catch((error) => this.logError(error));
		};
	}
}