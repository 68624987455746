namespace blocks.errorLogging {
	"use strict";

	export interface INotificationLoggingService {
		logErrorMessage(error: any): void;
		logDebugMessage(message: any): void;
		logServiceError(message: any): void;
	}

	class NotificationLoggingService implements INotificationLoggingService {
        serviceBase: string = "/api/errorlogjavascriptnotification/";

		static $inject: string[] = ["$log", "$window"];
		constructor(private $log: ng.ILogService, private $window: ng.IWindowService) { }

		logErrorMessage(message: any): void {
			this.$log.error.apply(this.$log, arguments);

            $.ajax({
                type: "POST",
                url: this.serviceBase + "create",
                contentType: "application/json",
                data: this.formatPostData(message, "Error")
            });
		}

		logDebugMessage(message: any): void {
            // preserve default behaviour
            this.$log.error.apply(this.$log, arguments);
            // send server side
            $.ajax({
                type: "POST",
                url: this.serviceBase + "create",
                contentType: "application/json",
                data: this.formatPostData(message, "Debug")
            });
		}d

		logServiceError(error: any): void {
            let message = {
                "message": error.data.message,
                "url": error.config.url,
                "method": error.config.method,
                "status": error.status
            };

            this.logErrorMessage(JSON.stringify(message));
		}

        formatPostData(message: any, messageType: string) {
            return angular.toJson({
                requestUri: this.$window.location.href,
                message: message,
                type: messageType
            });
        }
	}

	angular
		.module("blocks.errorLogging")
		.service("notificationLoggingService", NotificationLoggingService)
}