namespace app.meetings.services {
    "use strict";

    export interface IMeetingAttendanceStatusService {
        readAllRecords(meetingKey: number): ng.IPromise<core.interfaces.IKeyStringValue[]>;
    }

    class MeetingAttendanceStatusService extends app.core.services.BaseService implements IMeetingAttendanceStatusService {
        serviceBase: string = "/api/meetingattendancestatus/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
            $http: ng.IHttpService,
            $q: ng.IQService,
            notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService);
        }

		readAllRecords(meetingKey: number): ng.IPromise<core.interfaces.IKeyStringValue[]> {
			return this.$http
				.get(this.serviceBase + "readall/" + meetingKey)
				.then((response: ng.IHttpPromiseCallbackArg<core.interfaces.IKeyStringValue[]>): core.interfaces.IKeyStringValue[] => response.data)
				.catch((error) => this.logError(error));
		};
    }

    angular
        .module("app.meetings")
        .service("meetingAttendanceStatusService", MeetingAttendanceStatusService);
}