namespace app.documents {
    "use strict";

    export interface IDocumentCategoryTypeConstants {
        documents: number;
        restrictedDocuments: number;
        documentsReadPriorToMeeting: number;
        hotelBookingForm: number;
        meetingAgenda: number;
        meetingDocuments: number;
        meetingMinutes: number;
    }

    angular
        .module("app.documents")
        .constant("documentCategoryTypeConstants", <IDocumentCategoryTypeConstants>{
            documents: 1,
            restrictedDocuments: 2,
            documentsReadPriorToMeeting: 3,
            hotelBookingForm: 4,
            meetingAgenda: 5,
            meetingDocuments: 6,
            meetingMinutes: 7
        });
}
