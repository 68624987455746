namespace app.pages {
    "use strict";

    interface ILinksVm {
        page: pages.interfaces.IPage;
        formLoading: boolean;
    }

    class LinksController implements ILinksVm {
        page: pages.interfaces.IPage;
        formLoading: boolean = true;

        static $inject: string[] = ["$location", "$q", "pageConstants", "pageService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private pageConstants: IPageConstants,
            private pageService: services.IPageService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;

            let promises: ng.IPromise<any>[] = [
                this.pageService.readRecord(this.pageConstants.Links)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(page: pages.interfaces.IPage) {
            this.page = page;
            this.formLoading = false;
        }
    }

    angular
        .module("app.pages")
        .controller("LinksController", LinksController);
}