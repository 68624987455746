namespace app.publicPages {
    "use strict";

    interface IRegisterYourDetailsStage2Vm {
        formSubmitting: boolean;
        submitClick(invalidForm: boolean): void;
        record: subscribers.interfaces.ISubscriber;
    }

    class RegisterYourDetailsStage2Controller implements IRegisterYourDetailsStage2Vm {
        formSubmitting: boolean = false;
        record: subscribers.interfaces.ISubscriber;

        static $inject: string[] = ["$location", "subscriberService"];
        constructor(
            private $location: ng.ILocationService,
            private subscriberService: subscribers.services.ISubscriberService) {

        }

        submitClick(invalidForm: boolean): void {
			if (invalidForm === false) {
				this.saveRecord();
			}
        }

        saveRecord(): void {
            this.formSubmitting = true;

            this
                .subscriberService
                .createRecord(this.record)
                .then(() => {
                    this.formSubmitting = false;
                    this.$location.path("/registeryourdetails/stage3");
                });
        }
    }

    angular
        .module("app.publicPages")
        .controller("RegisterYourDetailsStage2Controller", RegisterYourDetailsStage2Controller);
}