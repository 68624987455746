(function () {
    "use strict";

    angular.module("app.widgets")
        .directive("cwnMeetingVenueDisplay", cwnMeetingVenueDisplay);

    /* @ngInject */
    function cwnMeetingVenueDisplay() {
        var directive = {
            scope: {
                venue: "="
            },
            restrict: "E",
            replace: true,
            link: link
        };
        return directive;


        function link(scope, element, attrs) {
            scope.$watch("venue", function (value) {
                if (value) {
                    element.html(formatVenue(value));
                }
            });
        }

        function formatVenue(venue) {
            var venueAddress;

            if (venue.websiteAddress) {
                venueAddress = "<a href=\"" + formatUrl(venue.websiteAddress) + "\" target=\"_blank\">" + venue.name + "</a>";
            } else {
                venueAddress = venue.name;
            }

            if (venue.addressLine1) {
                venueAddress = venueAddress + ", " + venue.addressLine1;
            }

            if (venue.addressLine2) {
                venueAddress = venueAddress + ", " + venue.addressLine2;
            }

            if (venue.addressLine3) {
                venueAddress = venueAddress + ", " + venue.addressLine3;
            }

            if (venue.addressLine4) {
                venueAddress = venueAddress + ", " + venue.addressLine4;
            }

            if (venue.addressLine5) {
                venueAddress = venueAddress + ", " + venue.addressLine5;
            }

            return venueAddress;
        }

        function formatUrl(url) {
            if (url.indexOf("http://") === 0 || url.indexOf("https://") === 0) {
                return url;
            } else {
                return "http://" + url;
            }
        }
    }
})();
