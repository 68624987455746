namespace app.publicPages {
	"use strict";

	interface ILoginVm {
		loggingIn: boolean;
		displayUnsuccessfulLoginMessage: boolean;
		loginClick(invalidForm: boolean): void;
		record: app.core.security.ILoginDetails;
	}

	class LoginController implements ILoginVm {
		loggingIn: boolean = false;
		displayUnsuccessfulLoginMessage: boolean = false;
		record: app.core.security.ILoginDetails;

		static $inject: string[] = ["$location", "userCredentials", "registerClientService", "loginService", "loginRedirectService"];
		constructor(
			private $location: ng.ILocationService,
			private userCredentials: app.core.security.IUserCredentials,
			private registerClientService: app.core.security.services.IRegisterClientService,
			private loginService: app.core.security.services.ILoginService,
			private loginRedirectService: app.core.security.services.ILoginRedirectService) {
			}

		loginClick(invalidForm: boolean) {
            if (invalidForm === false) {
                this.loggingIn = true;
				this.loginAdministrator();
            }
        }

		loginAdministrator(): void {
			this.registerClientService
				.registerClient()
				.then((clientDetails: app.core.security.IClientDetails) => {
					this.loginService
						.login(clientDetails, this.record)
						.then((successfulLoginDetails: app.core.security.ISuccessfulLoginDetails) => {
							this.userCredentials.store(successfulLoginDetails, this.record.rememberMyDetails);
							this.loginRedirectService.redirectPostLogin();
						})
						.catch(() => {
							this.loggingIn = false;
							this.displayUnsuccessfulLoginMessage = true;
						});
				});
		}
	}

	angular
		.module("app.publicPages")
		.controller("LoginController", LoginController);
}