namespace app.widgets {
    "use strict";

    interface ICwnDocumentSearchVm {
        documentSearchText: string;
    }

	class CwnDocumentSearchController implements ICwnDocumentSearchVm {
        documentSearchText: string;

		static $inject: string[] = ["$location"];
        constructor(private $location: ng.ILocationService) {
        }

        documentSearch() {
            if (this.documentSearchText) {
                var documentSearchText = this.documentSearchText;
                this.documentSearchText = "";

                this.$location.path("/documentsearch/" + documentSearchText);
            }
        };
    }

    interface IDirectiveScope {
    }

    class CwnDocumentSearch implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnDocumentSearch;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnDocumentSearch/cwnDocumentSearch.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;
		controller = CwnDocumentSearchController;
    }

    angular
        .module("app.widgets")
        .directive("cwnDocumentSearch", CwnDocumentSearch.instance);
}