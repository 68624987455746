namespace blocks.kendoConfiguration {
	export interface IKendoNotification {
		configure(): kendo.ui.NotificationOptions;
	}

	class KendoNotification implements IKendoNotification {
		configure(): kendo.ui.NotificationOptions {
			return <kendo.ui.NotificationOptions>{
				autoHideAfter: 6000,
				stacking: "down",
				position: <kendo.ui.NotificationPosition> {
					top: 20,
					right: 30,
					bottom: null,
					left: null
				},
				templates: <kendo.ui.NotificationTemplate[]> [
					{
						type: "save",
						template: "<div class='notification-wrapper'><h2>#= message #</h2></div>"
					}
				]
			};
		}
	}

	angular
		.module("blocks.kendoConfiguration")
		.service("kendoNotification", KendoNotification);
}