namespace app.widgets {
    "use strict";

    interface ICwnMenuGroupsVm {
        workingGroups: workingGroups.interfaces.IWorkingGroup[];
	}

	class CwnMenuGroupsController implements ICwnMenuGroupsVm {
        workingGroups: workingGroups.interfaces.IWorkingGroup[];

		static $inject: string[] = ["$scope", "workingGroupService", "workingGroupTypeConstants", "widgetRefresh"];
        constructor(
            private $scope: ng.IScope,
            private workingGroupService: workingGroups.services.IWorkingGroupService,
            private workingGroupTypeConstants: workingGroups.IWorkingGroupTypeConstants,
            private widgetRefresh: IWidgetRefreshValues) {

			this.$scope.$watch(
				() => this.widgetRefresh.cwnMenuGroups,
				(refresh) => {
					if (refresh === true) {
                        this.readAllGroups();
					}
				});
        }

        readAllGroups() {
            this.workingGroupService
                .readByType(this.workingGroupTypeConstants.WorkingGroup)
                .then((workingGroups: workingGroups.interfaces.IWorkingGroup[]) => {
                    this.workingGroups = workingGroups;
                    this.widgetRefresh.cwnMenuGroups = false;
                });
        }
    }

    interface IDirectiveScope {
    }

    class CwnMenuGroups implements ng.IDirective {
        static instance(): ng.IDirective {
            return new CwnMenuGroups;
        }

        scope: IDirectiveScope = {
        };
        restrict: string = "E";
        templateUrl: string = "/app/widgets/cwnMenuGroups/cwnMenuGroups.html";
		controller = CwnMenuGroupsController;
		controllerAs: string = "vm";
		bindToController: boolean = true;
    }

    angular
        .module("app.widgets")
        .directive("cwnMenuGroups", CwnMenuGroups.instance);
}