namespace app.widgets {
	"use strict";

    interface ICwnMeetingDisplayVm {
        documentCategoryTypeConstants: documents.IDocumentCategoryTypeConstants;
	}

	class CwnMeetingDisplayController implements ICwnMeetingDisplayVm {
        static $inject: string[] = ["documentCategoryTypeConstants"];
        constructor(public documentCategoryTypeConstants: documents.IDocumentCategoryTypeConstants) {
		}
	}

    interface IDirectiveScope {
        meetingDetails: string;
    }

	class CwnMeetingDisplay implements ng.IDirective {
		static instance(): ng.IDirective {
			return new CwnMeetingDisplay;
		}

        scope: IDirectiveScope = {
            meetingDetails: "="
        };

		restrict: string = "E";
		templateUrl: string = "/app/widgets/cwnMeetingDisplay/cwnMeetingDisplay.html";
		controllerAs: string = "vm";
		bindToController: boolean = true;

        controller = CwnMeetingDisplayController;
    }

	angular
		.module("app.widgets")
		.directive("cwnMeetingDisplay", CwnMeetingDisplay.instance);
}