namespace app.meetings.services {
	"use strict";

    export interface IMeetingService {
        readAllByWorkingGroupKey(workingGroupKey: number, pagingParameters: core.interfaces.IPagingParameters): ng.IPromise<core.interfaces.ISearchResults<interfaces.IMeetingListReturnParameters[]>>;
        readNewMeetingInvitations(): ng.IPromise<number>;
        readCurrentMeetingInvitations(): ng.IPromise<interfaces.IMeetingInvitationsReturnType[]>;
        readRecord(recordKey: number): ng.IPromise<interfaces.IMeeting>;
        readMeetingAttendees(recordKey: number): ng.IPromise<interfaces.IGetReadMeetingAttendeesReturnType[]>;
        readMeetingDetails(recordKey: number): ng.IPromise<interfaces.IMeetingListReturnParameters[]>;
        readMeetingQuestions(recordKey: number): ng.IPromise<interfaces.IQuestion[]>;
        readUpcomingMeetingsByWorkingGroupKey(workingGroupKey: number): ng.IPromise<interfaces.IMeeting[]>;
    }

	class MeetingService extends app.core.services.BaseService implements IMeetingService {
		private serviceBase: string = "/api/meeting/";

        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
			$http: ng.IHttpService,
			$q: ng.IQService,
			notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
			super($http, $q, notificationLoggingService)
        }

		readAllByWorkingGroupKey(workingGroupKey: number, pagingParameters: core.interfaces.IPagingParameters): ng.IPromise<core.interfaces.ISearchResults<interfaces.IMeetingListReturnParameters[]>> {
            return this.$http
                .put(this.serviceBase + "readallbyworkinggroupkey/" + workingGroupKey, pagingParameters)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IMeetingListReturnParameters[]>): interfaces.IMeetingListReturnParameters[] => response.data)
                .catch((error) => this.logError(error));
		};

		readUpcomingMeetingsByWorkingGroupKey(workingGroupKey: number): ng.IPromise<interfaces.IMeeting[]> {
            return this.$http
                .get(this.serviceBase + "readupcomingmeetings/" + workingGroupKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IMeeting[]>): interfaces.IMeeting[] => response.data)
                .catch((error) => this.logError(error));
		};

		readRecord(recordKey: number): ng.IPromise<interfaces.IMeeting> {
            return this.$http
                .get(this.serviceBase + "read/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IMeeting>): interfaces.IMeeting => response.data)
                .catch((error) => this.logError(error));
		};

		readMeetingDetails(recordKey: number): ng.IPromise<interfaces.IMeetingListReturnParameters[]> {
            return this.$http
                .get(this.serviceBase + "readmeetingdetail/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IMeetingListReturnParameters[]>): interfaces.IMeetingListReturnParameters[] => response.data)
                .catch((error) => this.logError(error));
		};

		readMeetingQuestions(recordKey: number): ng.IPromise<interfaces.IQuestion[]> {
            return this.$http
                .get(this.serviceBase + "readmeetingquestions/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IQuestion[]>): interfaces.IQuestion[] => response.data)
                .catch((error) => this.logError(error));
		};

		readMeetingAttendees(recordKey: number): ng.IPromise<interfaces.IGetReadMeetingAttendeesReturnType[]> {
            return this.$http
                .get(this.serviceBase + "readmeetingattendees/" + recordKey)
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IGetReadMeetingAttendeesReturnType[]>): interfaces.IGetReadMeetingAttendeesReturnType[] => response.data)
                .catch((error) => this.logError(error));
		};

		readNewMeetingInvitations(): ng.IPromise<number> {
            return this.$http
                .get(this.serviceBase + "readnewmeetinginvitations")
                .then((response: ng.IHttpPromiseCallbackArg<number>): number => response.data)
                .catch((error) => this.logError(error));
		};

		readCurrentMeetingInvitations(): ng.IPromise<interfaces.IMeetingInvitationsReturnType[]> {
            return this.$http
                .get(this.serviceBase + "readcurrentmeetinginvitations")
                .then((response: ng.IHttpPromiseCallbackArg<interfaces.IMeetingInvitationsReturnType[]>): interfaces.IMeetingInvitationsReturnType[] => response.data)
                .catch((error) => this.logError(error));
		};
    }

	angular
		.module("app.meetings.services")
		.service("meetingService", MeetingService);
}