namespace blocks.errorLogging {

   exceptionHandler.$inject = ["exceptionLoggingService"];
   function exceptionHandler(exceptionLoggingService: blocks.errorLogging.IExceptionLoggingService) {
        return function (exception: Error, cause?: string) {
            return exceptionLoggingService.logException(exception, cause);
        };
   }

    angular
        .module("blocks.errorLogging")
        .factory("$exceptionHandler", exceptionHandler);
}