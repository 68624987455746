namespace app.meetings.services {
    "use strict";

    export interface IMeetingYesNoQuestionResponseService extends core.services.IReadAllService<core.interfaces.IStringStringValue> {
    }

    class MeetingYesNoQuestionResponseService extends core.services.ReadAllService<core.interfaces.IStringStringValue> implements IMeetingYesNoQuestionResponseService {
        static $inject: string[] = ["$http", "$q", "notificationLoggingService"];
        constructor(
            $http: ng.IHttpService,
            $q: ng.IQService,
            notificationLoggingService: blocks.errorLogging.INotificationLoggingService) {
            super($http, $q, notificationLoggingService, "/api/meetingyesnoquestionresponse/");
        }
    }

    angular
        .module("app.meetings")
        .service("meetingYesNoQuestionResponseService", MeetingYesNoQuestionResponseService);
}