namespace app.publicPages {
	angular
		.module("app.publicPages")
		.config(routeConfig);

	routeConfig.$inject = ["$routeProvider"];
	function routeConfig($routeProvider: ng.route.IRouteProvider): void {
		$routeProvider
			.when("/login", <app.core.router.ICwnRoute>{
				templateUrl: "/app/publicPages/login/login.html",
				controller: "LoginController",
				controllerAs: "vm",
				title: "Login",
				requireAuthentication: false
			})
			.when("/passwordreset/stage1", <app.core.router.ICwnRoute>{
				templateUrl: "/app/publicPages/passwordReset/stage1/passwordResetStage1.html",
				controller: "PasswordResetStage1Controller",
				controllerAs: "vm",
				title: "Password reset",
				requireAuthentication: false
			})
			.when("/passwordreset/stage2/:tokenId", <app.core.router.ICwnRoute>{
				templateUrl: "/app/publicPages/passwordReset/stage2/passwordResetStage2.html",
				controller: "PasswordResetStage2Controller",
				controllerAs: "vm",
				title: "Password reset",
				requireAuthentication: false
			})
			.when("/registeryourdetails/stage1", <app.core.router.ICwnRoute>{
				templateUrl: "/app/publicPages/registerYourDetails/stage1/registerYourDetailsStage1.html",
				controller: "RegisterYourDetailsStage1Controller",
				controllerAs: "vm",
				title: "Register your details",
				requireAuthentication: false
			})
			.when("/registeryourdetails/stage2", <app.core.router.ICwnRoute>{
				templateUrl: "/app/publicPages/registerYourDetails/stage2/registerYourDetailsStage2.html",
				controller: "RegisterYourDetailsStage2Controller",
				controllerAs: "vm",
				title: "Register your details",
				requireAuthentication: false
            })
			.when("/registeryourdetails/stage3", <app.core.router.ICwnRoute>{
				templateUrl: "/app/publicPages/registerYourDetails/stage3/registerYourDetailsStage3.html",
				controller: "RegisterYourDetailsStage3Controller",
				controllerAs: "vm",
				title: "Register your details",
				requireAuthentication: false
			})            ;
	}
}