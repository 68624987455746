namespace app.meetings {
    "use strict";

    interface IMeetingListVm {
        formLoading: boolean;
        meetingInvitations: interfaces.IMeetingInvitationsReturnType[];
    }

    class MeetingListController implements IMeetingListVm {
        formLoading: boolean = true;
        meetingInvitations: interfaces.IMeetingInvitationsReturnType[];

        static $inject: string[] = ["$location", "$q", "meetingService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private meetingService: services.IMeetingService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;

            let promises: ng.IPromise<any>[] = [
                this.meetingService.readCurrentMeetingInvitations()
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(meetingInvitations: interfaces.IMeetingInvitationsReturnType[]) {
            this.meetingInvitations = meetingInvitations;
            this.formLoading = false;
        }
    }

    angular
        .module("app.meetings")
        .controller("MeetingListController", MeetingListController);

}