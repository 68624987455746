(function () {
    "use strict";

    angular.module("app.widgets")
        .directive("cwnMemberAddress", cwnMemberAddress);

    /* @ngInject */
    function cwnMemberAddress() {
        var directive = {
            scope: {
                subscriber: "="
            },
            restrict: "E",
            replace: true,
            link: link
        };
        return directive;

        function link(scope, element) {
            var memberAddress;

            memberAddress = scope.subscriber.companyName + " | " + scope.subscriber.addressLine1;

            if (scope.subscriber.addressLine2) {
                memberAddress = memberAddress + " | " + scope.subscriber.addressLine2;
            }

            if (scope.subscriber.addressLine3) {
                memberAddress = memberAddress + " | " + scope.subscriber.addressLine3;
            }

            if (scope.subscriber.addressLine4) {
                memberAddress = memberAddress + " | " + scope.subscriber.addressLine4;
            }

            if (scope.subscriber.country) {
                memberAddress = memberAddress + " | " + scope.subscriber.country;
            }

            element.text(memberAddress);
        }
    }
})();
