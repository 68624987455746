namespace app.workingGroups {
    "use strict";

    export interface IWorkingGroupTypeConstants {
        WorkingGroup: number;
        SubWorkingGroup: number;
    }

    angular
        .module("app.workingGroups")
        .constant("workingGroupTypeConstants", <IWorkingGroupTypeConstants>{
            WorkingGroup: 0,
            SubWorkingGroup: 1
        });
}
