namespace app.workingGroups {
    "use strict";

    interface IWorkingGroupDocumentsVm {
        archived: boolean;
        bindData: boolean;
        formLoading: boolean;
        refreshData: boolean;
        workingGroup: interfaces.IWorkingGroup;
        documentArchivedClick(): void;
    }

    class WorkingGroupDocumentsController implements IWorkingGroupDocumentsVm {
        archived: boolean = false;
        bindData: boolean = false;
        refreshData: boolean = false;
        formLoading: boolean = true;
        workingGroup: interfaces.IWorkingGroup;

        record: interfaces.IWorkingGroup = {
            group: parseInt(this.$routeParams.group),
            subgroup: parseInt(this.$routeParams.subgroup),
            id: parseInt(this.$routeParams.subgroup || this.$routeParams.group)
        }

        static $inject: string[] = ["$location", "$q", "$routeParams", "documentCategoryTypeConstants", "workingGroupService", "refreshTokenService", "widgetRefresh"];
        constructor(
            private $location: ng.ILocationService,
            private $q: ng.IQService,
            private $routeParams: interfaces.IWorkingGroupRouteParams,
            private documentCategoryTypeConstants: documents.IDocumentCategoryTypeConstants,
            private workingGroupService: services.IWorkingGroupService,
            private refreshTokenService: core.security.services.IRefreshTokenService,
            private widgetRefresh: widgets.IWidgetRefreshValues) {

            refreshTokenService.refreshToken().then(() => {
                this.activate();
            });
        }

        activate() {
            this.widgetRefresh.cwnMenuGroups = true;
            this.bindData = true;

            let promises: ng.IPromise<any>[] = [
                this.workingGroupService.readRecord(this.record.id)
            ];

            return this.$q
                .all(promises)
                .then((results) => this.writeFormValues.apply(this, results));
        }

        writeFormValues(workingGroup: interfaces.IWorkingGroup) {
            this.workingGroup = workingGroup;
            this.formLoading = false;
        }

        documentArchivedClick() {
            this.archived = !this.archived;
            this.refreshData = true;
        }

        searchData = (recordsPerPage: number, pageNumber: number) => {
            var searchParameters: core.interfaces.ISearchParameters<documents.interfaces.IDocument> = {
                recordsPerPage: recordsPerPage,
                pageNumber: pageNumber,
                filter: {
                    id: 0,
                    archived: this.archived
                }
            };

            return this
                .workingGroupService
                .readPublicDocuments(this.record.id, searchParameters);
         }
    }

    angular
        .module("app.workingGroups")
        .controller("WorkingGroupDocumentsController", WorkingGroupDocumentsController);

}